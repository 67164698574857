/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                DEPENDENCIES                                */
/* -------------------------------------------------------------------------- */
// Assets
import IMAGE_1 from '../../images/other-programs-page/Euraxess.jpg';
import IMAGE_2 from '../../images/other-programs-page/prima.jpg';
import IMAGE_3 from '../../images/other-programs-page/bluemed.jpg';

/* -------------------------------------------------------------------------- */
/*                     PROGRAMS STRATEGICAL AXES EURAXESS                     */
/* -------------------------------------------------------------------------- */
export const EURAXESS_PROGRAMS_AXES_STRATEGIES = [
  {
    id: 1,
    axe: "Renforcer la visibilité des opportunités de mobilité disponibles et des services entre la Tunisie et les autres pays de l'EER et au-delà, pour les bénéficiaires nationaux et étrangers.",
  },
  {
    id: 2,
    axe: 'Développer les capacités des acteurs tunisiens de la R&I à participer aux offres de mobilité.',
  },
  {
    id: 3,
    axe: "Institutionnaliser et professionnaliser l'offre de services d’assistance aux chercheurs mobiles Le Réseau EURAXESS opère à travers quatre plateformes d’intervention : EURAXESS Jobs, EURAXESS Services, EURAXESS Rights et EURAXESS Links.",
  },
];

/* -------------------------------------------------------------------------- */
/*                      OTHER NETWORKS AND PROGRAMS DATA                      */
/* -------------------------------------------------------------------------- */
export const OTHER_NETWORKS_AND_PROGRAMS = [
  {
    id: 1,
    category: 'Euraxess',
    image: IMAGE_1,
    description:
      'La mise en place du Réseau Tunisien EURAXESS-TEN assure l’adhésion de la Tunisie au réseau international EURAXESS qui rassemble plus de 600 centres de services aidant les chercheurs internationaux dans 42 pays. Il est destiné à soutenir et développer la carrière des chercheurs, tout en renforçant la collaboration scientifique entre la Tunisie et les autres pays de l’Espace Européen de la Recherche - EER - et au-delà. Pour répondre à cet objectif global, le projet TEN s’appuiera sur 3 axes stratégiques :',
    result: [
      {
        id: 1,
        subResult:
          'Promouvoir l’attractivité de la Tunisie en tant que destination de la Recherche',
      },
      {
        id: 2,
        subResult:
          'Accroître la visibilité et développer la notoriété du Réseau en Tunisie',
      },
      {
        id: 3,
        subResult:
          'Consolider les synergies avec les différentes parties prenantes, et coordonner l’offre de services dans le cadre d’un partenariat stratégique.',
      },
      {
        id: 4,
        subResult:
          'Contribuer à la promotion de l’excellence scientifique tunisienne à l’international.',
      },
    ],
  },

  {
    id: 2,
    category: 'Prima',
    image: IMAGE_2,
    description:
      "The Partnership for Research and Innovation in the Mediterranean Area [PRIMA] rassemble des États membres de l'Union européenne, des pays associés à Horizon 2020, dont la Tunisie, et des pays partenaires méditerranéens sur un même pied d'égalité (copropriété, cogestion et cofinancement) avec la participation de la Commission européenne. PRIMA a pour vision de rendre les sociétés méditerranéennes davantage inclusives, saines et prospères, grâce à des solutions innovantes dans les systèmes agroalimentaires et hydrauliques, contribuant, d'ici la fin du programme, à l'utilisation durable des ressources naturelles, à la croissance économique et à la stabilité. Le partenariat est financé par une combinaison de financement des États participants à PRIMA (actuellement 274 millions d'Euros), on parle alors de PRIMA Section 2, et d'une contribution de 220 millions d'Euros de l'UE via Horizon 2020, soit le programme de financement de la recherche et de l'innovation (2014-2020), constituant ainsi PRIMA section 1.  En Tunisie, les appels PRIMA section 1 sont coordonnés par l’Unité de Gestion par Objectifs du Programme Européen de Recherche et Innovation-Horizon 2020.",
    objectifs_content:
      "Renforcer les capacités de recherche et d'innovation et développer des connaissances et des solutions innovantes communes pour :",
    objectifs: [
      {
        id: 1,
        subObjectif:
          'Les systèmes agro-alimentaires afin de les rendre durables',
      },
      {
        id: 2,
        subObjectif:
          "L’approvisionnement et la gestion intégrés de l'eau dans la région méditerranéenne. Le but étant de rendre les systèmes de fourniture et de gestion de cette ressource précieuse plus résilients au changement climatique, bien plus efficaces, rentables et écologiquement et socialement durables. Ce faisant, l’on contribuera à résoudre bien en amont les problèmes de pénurie d'eau, de sécurité alimentaire, de nutrition, de santé et de bien-être.",
      },
    ],
    result: [
      {
        id: 1,
        subResult:
          "Gestion durable des sols et de l'eau pour lutter contre la dégradation des terres et la désertification et promouvoir la restauration des écosystèmes.",
      },
      {
        id: 2,
        subResult:
          'Accroître les performances environnementales et socio-économiques des systèmes agricoles à petite échelle grâce à des améliorations des aspects organisationnels et à de nouveaux modèles de gouvernance de la chaîne de valeur.',
      },
      {
        id: 3,
        subResult:
          "Accroître l'adhésion au régime méditerranéen en tant que modèle durable incluant les aspects environnementaux, sociaux et sanitaires..",
      },
      {
        id: 4,
        subResult:
          'Contribuer à la promotion de l’excellence scientifique tunisienne à l’international.',
      },
    ],
    result_content:
      'Tirer parti des connaissances sur la gestion Nexus des ressources Eau-Energie-Alimentation-Ecosystèmes dans la région méditerranéenne: des concepts aux solutions pratiques.',
    impact: [
      {
        id: 1,
        subImpact:
          "Intégrer l'innovation dans les chaînes agro-alimentaires méditerranéennes par les petits exploitants et les PME",
      },
      {
        id: 2,
        subImpact:
          "Œuvrer pour une réutilisation et dessalement de l'eau pour la production agricole et alimentaire",
      },
      {
        id: 3,
        subImpact:
          'Mettre en œuvre une gestion durable et intégrée des ressources en eau dans la Méditerranée, dans ce contexte de changement climatique.',
      },
      {
        id: 4,
        subImpact:
          "Démontrer les avantages de l'approche Nexus eau-écosystème-nourriture pour développement économique optimal, atteignant un niveau élevé de protection de l'environnement et garantissant accès aux ressources naturelles",
      },
      {
        id: 5,
        subImpact: 'Améliorer la durabilité des agroécosystèmes méditerranéens',
      },
      {
        id: 6,
        subImpact:
          "Mise en place d'outils analytiques et de technologies numériques pour assurer la traçabilité et le contrôle de l'authenticité des aliments traditionnels méditerranéens.",
      },
      {
        id: 7,
        subImpact:
          'Valoriser les bienfaits pour la santé des produits alimentaires traditionnels méditerranéens',
      },
    ],
  },

  {
    id: 3,
    category: 'Bluemed',
    image: IMAGE_3,
    description:
      "BlueMed est une initiative qui a été élaborée entre plusieurs pays du pourtour méditerranéen, à savoir Chypre, la Croatie, la France, la Grèce, l'Italie, Malte, le Portugal, la Slovénie et l'Espagne, et facilitée avec le soutien de la Commission européenne en 2014, afin de promouvoir la recherche et l’innovation ayant trait à des défis en relation avec la mer méditerranéenne, notamment la pêche, le tourisme, l’emploi, etc. La feuille de route de BlueMed a été définie autour de quatre plateformes dédiées respectivement à la connaissance, à l'économie, à la technologie et à la gouvernance. Ces plateformes constituent un outil pour développer la coopération et la mise en commun des infrastructures de recherche, le partage des ressources et des connaissances pour aboutir à une recherche plus concertée, plus efficace et plus collaborative.",
    description2:
      "La Tunisie a contribué et bénéficié de plusieurs activités telles que: Action pilote BlueMed sur une mer Méditerranée saine et sans plastique (conférence phare en juin 2019); les 4 plateformes (connaissance, économie, technologie, politique); Start-up Actions (SEALINES: Réseau méditerranéen de sécurité; ECOMEDPORT: Étude de faisabilité d'une usine orientée vers l'écosystème pour la gestion des sédiments dans les ports et marinas méditerranéens; BlueBoatsMed: La prospective sur le bateau de croisière, son potentiel de transition vers une économie bleue en Méditerranée et les enjeux environnementaux associés) ; et les jeunes ambassadeurs de la communication BlueMed. ",
    objectifs: [
      {
        id: 1,
        subObjectif:
          'Mieux intégrer les connaissances et les efforts pour favoriser la croissance bleue dans le bassin méditerranéen.',
      },
      {
        id: 2,
        subObjectif:
          "Exploiter le potentiel des secteurs marins et maritimes pour créer de nouveaux emplois «bleus», promouvoir la cohésion sociale et améliorer l'état de l'environnement et le bien-être des citoyens",
      },
      {
        id: 3,
        subObjectif:
          "Elaboration d'un Agenda Stratégique de Recherche et d’Innovation (SRIA) définissant des priorités.",
      },
      {
        id: 4,
        subObjectif:
          'Faire des propositions pour des actions conjointes transnationales',
      },
      {
        id: 5,
        subObjectif: '4 « Plaforms »',
      },
      {
        id: 6,
        subObjectif: 'Un « pivot national » par plate-forme',
      },
    ],
    descrip_result:
      'Treize priorités ont été identifiées et les pays en charge du développement du plan de mise en œuvre pour chaque priorité ont été désignés :',
    result: [
      {
        id: 1,
        subResult:
          'Comprendre les impacts de la pollution, l’atténuation de ses effets et son traitement en Méditerranée (Italie et Tunisie).',
      },
      {
        id: 2,
        subResult:
          'Soutenir les solutions pour une production alimentaire durable',
      },
      {
        id: 3,
        subResult:
          "Se préparer au changement climatique et définir des mesures d'adaptation et d'atténuation",
      },
      {
        id: 4,
        subResult: "Vers un réseau de systèmes d'observation",
      },
      {
        id: 5,
        subResult: 'Établir un lien entre tourisme et environnement ',
      },
      {
        id: 6,
        subResult: "Aménagement efficace de l'espace maritime en Méditerranée",
      },
      {
        id: 7,
        subResult:
          'Écologisation des navires, des installations et des services',
      },
      {
        id: 8,
        subResult: 'Explorer le potentiel de la biotechnologie bleue',
      },
      {
        id: 9,
        subResult:
          'Promouvoir le rôle des énergies marines renouvelables dans la phase de transition ',
      },
      {
        id: 10,
        subResult: 'Données ouvertes, science ouverte, innovation ouverte',
      },
      {
        id: 11,
        subResult:
          'Renforcement des capacités, compétences bleues et professionnels bleus',
      },
      {
        id: 12,
        subResult:
          'Renforcer les synergies entre la science, l’industrie, les décideurs et la société ',
      },
      {
        id: 13,
        subResult:
          'Des activités économiques maritimes traditionnelles aux activités de croissance bleue',
      },
    ],
    impact: [
      {
        id: 1,
        subImpact: 'Explorer le potentiel de la biotechnologie bleue',
      },
      {
        id: 2,
        subImpact:
          'Renforcer les synergies entre la science, l’industrie, les décideurs et la société',
      },
      {
        id: 3,
        subImpact: "Vers un réseau de systèmes d'observation",
      },
      {
        id: 4,
        subImpact:
          'Soutenir les solutions pour une production alimentaire durable',
      },
    ],
  },
];
