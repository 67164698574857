/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// UI local components
import UtileLinks from '../../../helpers/usefull_link';

// FAKE APIS
import {
  OTHER_NETWORKS_AND_PROGRAMS,
  EURAXESS_PROGRAMS_AXES_STRATEGIES,
} from '../../../shared/fake-APIs/otherNetworks';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             Events Section                                 */
/* -------------------------------------------------------------------------- */

function OtherNetworksPrograms({ buttonValue }) {
  /* --------------------------------- HELPERS -------------------------------- */
  // fromat description:
  function formatDesc(desc) {
    let newDesc = '';
    for (let i = 0; i < desc.length; i += 1) {
      newDesc += desc.charAt(i);
      const check = desc.charAt(i);
      if (check === '.') {
        newDesc += ' <br> ';
      }
    }
    return { __html: newDesc };
  }
  /* ******************************** RENDERING ******************************* */
  return (
    <div className="Other-networks-container fluid">
      {OTHER_NETWORKS_AND_PROGRAMS.filter((network) => {
        if (buttonValue === '' || buttonValue === 'undefined') {
          return network.category === 'Euraxess';
        }
        return network.category === buttonValue;
      }).map((network) => (
        <div className="Other-networks flex justify-between">
          <Container className="Other-networks__image fluid">
            <img src={network.image} alt={network.category} />
          </Container>
          <Container className="Other-networks__content fluid" key={network.id}>
            {/* PROGRAMS DESCRIPTION */}
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={formatDesc(network.description)} />
            {network.description2 && (
              <p className="top">{network.description2}</p>
            )}

            {/* additional informations for EURAXESS LEVEL */}
            {(buttonValue === '' ||
              buttonValue === 'undefined' ||
              buttonValue === 'Euraxess') && (
              <>
                <ol className="top">
                  {EURAXESS_PROGRAMS_AXES_STRATEGIES.map((axe) => (
                    <li className="top" key={axe.id}>
                      <span>
                        {axe.id}
                        -&ensp;
                      </span>
                      {axe.axe}
                    </li>
                  ))}
                </ol>

                <h2>Ses principales activités couvrent :</h2>
                <ul className="top">
                  <li>
                    L&apos;offre d&apos;informations pratiques sur les
                    opportunités de mobilité et les services offerts aux
                    chercheurs mobiles tunisiens.
                  </li>
                  <li>
                    L&apos;offre de services de soutien aux chercheurs mobiles
                    concernant les conditions d&apos;entrée, les procédures
                    administratives et la vie en Tunisie.
                  </li>
                  <li>
                    L&apos;amélioration de la visibilité du potentiel de la R&I
                    tunisienne à l&apos;international.
                  </li>
                </ul>
              </>
            )}

            {/* WHAT PROGRAMS AIMS TO */}
            {network.objectifs && <h2 className="mt-4">Objectifs :</h2>}
            {network.objectifs_content && (
              <p className="top">{network.objectifs_content}</p>
            )}
            {network.objectifs && (
              <ul className="top">
                {network.objectifs.map((objectif) => (
                  <li key={objectif.id}>
                    {objectif.subObjectif}
                    {objectif.id === 5 && (
                      <ul className="top">
                        <li>
                          Knowledge: « Knowledge of Mediterranean Sea dynamics
                          and ecosystems »
                        </li>
                        <li>
                          Economy : « Sectors of Blue Growth economy in the
                          Mediterranean »
                        </li>
                        <li>
                          Technology : « Enabling technologies for BG in the
                          Mediterranean »
                        </li>
                        <li>Policy : « Science to Policy for Blue Growth »</li>
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}

            {/* PROGRAMS RESULTS */}
            {network.result && <h2 className="mt-4">Résultats :</h2>}
            {network.descrip_result && (
              <p className="top">{network.descrip_result}</p>
            )}
            <ul className="top">
              {network.result.map((result) => (
                <li key={result.id}>{result.subResult}</li>
              ))}
            </ul>
            {network.result_content && (
              <p className="top">{network.result_content}</p>
            )}

            {/* PROGRAMS IMPACT */}
            {network.impact && <h2 className="mt-4">Impact sur le SNRI :</h2>}
            {network.impact && (
              <ul className="top">
                {network.impact.map((impact) => (
                  <li key={impact.id}>{impact.subImpact}</li>
                ))}
              </ul>
            )}

            {/* USEFULL LINKS */}
            <h5 className="mt-4">Liens utiles :</h5>

            {/* Default useful link */}
            {(buttonValue === '' ||
              buttonValue === 'undefined' ||
              buttonValue === 'Euraxess') && (
              <Container className="top fluid">
                <UtileLinks
                  URL="https://euraxess.ec.europa.eu/"
                  title="Euraxess"
                />
              </Container>
            )}

            {/* Prima usefull link */}
            {buttonValue === 'Prima' && (
              <Container className="top fluid">
                <UtileLinks URL="https://prima-med.org/" title="Prima" />
              </Container>
            )}

            {/* Bluemed usefull link */}
            {buttonValue === 'Bluemed' && (
              <Container className="top fluid">
                <UtileLinks
                  URL="http://www.bluemed-initiative.eu/"
                  title="Bluemed"
                />
              </Container>
            )}
          </Container>
        </div>
      ))}
    </div>
  );
}

OtherNetworksPrograms.propTypes = {
  buttonValue: PropTypes.string.isRequired,
};

export default OtherNetworksPrograms;
