/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';

import { graphql, Link } from 'gatsby';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/BannerSection';
import OtherNetworksPrograms from '../page-components/page-other-networks-and-programs/network-content';

// Local API
import { otherNeworksList } from '../shared/local-links/underMenuLinks';

import BANNER_IMG from '../images/other-programs-page/banner.png';

// Style
import '../page-styles/our-network.scss';
import '../page-styles/other-networks-and-programs.scss';

/* -------------------------------------------------------------------------- */
/*                      Other Networks and Programs Page                      */
/* -------------------------------------------------------------------------- */

function OtherNetworksProgramsPage({ location }) {
  /* --------------------------------- HOOKS -------------------------------- */
  const [activeIndex, setActiveIndex] = useState(0);
  const [buttonValue, setButtonValue] = useState('');

  /* --------------------------------- CONST -------------------------------- */
  const activateLink = (index) => {
    setActiveIndex(index);
  };

  // Menu
  const Menu = otherNeworksList.map((link, index) => {
    return (
      <Link
        key={link.id}
        to={link.link}
        id={activeIndex === index ? 'active' : 'unactive'}
        className="wide-single-link single-link text-center flex justify-center items-center active"
        onClick={() => {
          activateLink(index);
          setButtonValue(link.title);
        }}
        onKeyDown={() => activateLink(index)}
      >
        <li>
          <h5>{link.title}</h5>
        </li>
      </Link>
    );
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Autres Réseaux et Programmes" />
      <Container className="other-networks-and-programs max-w fluid" fluid>
        <BannerSection
          title="Autres réseaux et programmes"
          bannerImage={BANNER_IMG}
          description="Dans le cadre de sa mission, l’Unité de Gestion chargée des
          programmes cadres européens pour la recherche et l’innovation assure le suivi
          et la gestion d’autres initiatives et programmes transversaux lancés par la
          Commission européenne."
        />

        <Container className="menu-section max-w fluid">
          <Container>
            <ul className="topMenu flex fluid">{Menu}</ul>
          </Container>
        </Container>

        <Container>
          <Visible lg xl xxl>
            <Container className="top flex items-center fluid">
              <Link className="breadcrumb__link" to="/">
                Accueil
              </Link>
              <span className="m-r-l">/</span>
              <Link
                className="breadcrumb__link"
                to={`/${location.pathname.split('/')[1]}`}
              >
                Autres réseaux et programmes
              </Link>
            </Container>
          </Visible>

          <Container className="top fluid">
            <OtherNetworksPrograms buttonValue={buttonValue} />
          </Container>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

OtherNetworksProgramsPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default OtherNetworksProgramsPage;
